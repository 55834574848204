<template>
  <div id="navbar-header">
    <b-navbar :fixed-top="fixedTop" transparent :mobile-burger="false">
      <template slot="brand">
        <div
          class="columns is-multiline is-gapless is-mobile is-centered is-vcentered"
        >
          <div class="column is-full">
            <b-navbar-item tag="router-link" :to="{ path: '/ligas' }">
              <img
                src="../../assets/imagens/logotipo_green_10.png"
                alt="Escala 10"
                :style="$mq == 'sm' ? 'width: 75px;' : ''"
              />
            </b-navbar-item>
          </div>
        </div>
      </template>

      <template slot="start">
        <div v-if="$mq != 'sm'">
          <div v-if="showHeader && limitAccess" class="banner-header">
            <div>
              Sua Assinatura possui um acesso limitado, sem permissão para
              algumas telas,<br />
              ou expirando antes do final da temporada.
              <router-link
                :to="'/renewSignature/' + userId + '?action=upgrade'"
              >
                <b class="has-text-white">Clique Aqui</b>
              </router-link>
              para atualizar a Assinatura!
            </div>
          </div>
        </div>
      </template>

      <template slot="end">
        <div class="group-config">
          <!--  Timer Component  -->
          <timer
            v-if="renderMercado && ($mq !== 'sm' || !openSideBar)"
            :clock="false"
            :starttime="startTime"
            :endtime="endTime"
            :trans="{
              days: 'dia(s)',
              hours: 'h',
              minutes: 'm',
              seconds: 's',
              running: 'fecha em',
              expired: '',
              status: {
                running: 'MERCADO ABERTO!',
                expired: 'MERCADO FECHADO!',
              },
            }"
            :style="
              $mq == 'sm' ? 'width: 150px; transform: scale(0.85,0.85);' : ''
            "
          ></timer>
          <!--  End! Timer Component  -->

          <a
            v-if="$mq != 'sm'"
            href="https://t.me/escala10"
            v-tooltip.bottom="{
              content: 'Telegram',
              classes: 'home-tooltip',
            }"
            target="_blank"
          >
            <b-icon pack="fab" icon="telegram-plane"></b-icon>
          </a>
          <a
            v-if="$mq != 'sm'"
            href="https://api.whatsapp.com/send?phone=5521974399122"
            v-tooltip.bottom="{
              content: 'Whatsapp',
              classes: 'home-tooltip',
            }"
            target="_blank"
          >
            <b-icon pack="fab" icon="whatsapp"></b-icon>
          </a>
          <a
            v-if="$mq != 'sm'"
            href="https://www.facebook.com/escala10.br/"
            v-tooltip.bottom="{
              content: 'Facebook',
              classes: 'home-tooltip',
            }"
            target="_blank"
          >
            <b-icon pack="fab" icon="facebook"></b-icon>
          </a>
          <a
            v-if="$mq != 'sm'"
            href="https://www.instagram.com/escala10.br/"
            v-tooltip.bottom="{
              content: 'Instagram',
              classes: 'home-tooltip',
            }"
            target="_blank"
          >
            <b-icon pack="fab" icon="instagram"></b-icon>
          </a>

          <NotificationAlert></NotificationAlert>

          <!-- Button Menu burger -->
          <b-button
            v-if="$mq === 'sm'"
            id="button-icon"
            :icon-left="openSideBar ? 'times' : 'bars'"
            icon-pack="fas"
            type="is-darkblue"
            style="margin-left: 15px; transition: all 0.2s ease"
            @click="troggleSidebar(!openSideBar)"
          >
            <span v-if="openSideBar">Menu</span>
          </b-button>
        </div>
        <b-navbar-item v-if="$mq != 'sm'" tag="div" id="group-user-dropdown">
          <b-dropdown
            ref="dropdown"
            v-model="dropDown"
            position="is-bottom-left"
            @active-change="(active) => (dropDown = active)"
          >
            <div slot="trigger" class="hd-group-user">
              <label class="user-name"
                >Olá, {{ userInfo.nome | formatterName }}</label
              >
              <button type="button" class="reset-bt bt-dropDown">
                <i
                  class="fas fa-angle-down icon-user"
                  :class="{ 'rt-180': dropDown }"
                ></i>
              </button>
            </div>

            <div class="dropdown-user">
              <ul>
                <li @click="gotoMeuDados()">
                  <button type="button" class="reset-bt">
                    <i class="fas fa-user-edit"></i> Meus Dados
                  </button>
                </li>
                <li v-on:click="signOutLogin()">
                  <button type="button" class="reset-bt">
                    <i class="fas fa-sign-out-alt"></i> Sair
                  </button>
                </li>
              </ul>
            </div>
          </b-dropdown>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import NotificationAlert from "./components/Notification";
import DownTimer from "../helpers/DownTimer";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavBar",
  components: {
    NotificationAlert,
    timer: DownTimer,
  },
  props: {
    fixedTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showHeader: false,
      limitAccess: false,
      dropDown: false,
      renderMercado: false,
      startTime: "",
      endTime: "",
    };
  },
  computed: {
    ...mapGetters(["userId", "userInfo", "mercado", "openSideBar"]),
  },
  async created() {
    this.limitAccess = this.$accessControl.checkLimitAccess();
    this.showHeader = true;

    await this.getMercado();
    this.startTime = new Date(this.mercado.inicio.replace("T", " "));
    this.endTime = new Date(this.mercado.fechamento.replace("T", " "));

    this.startTime = this.startTime.toString();
    this.endTime = this.endTime.toString();

    this.renderMercado = true;
  },
  methods: {
    ...mapActions(["getMercado", "signOut", "troggleSidebar"]),
    showDropDown: function() {
      this.dropDown = this.dropDown == false ? true : false;
    },
    gotoMeuDados() {
      this.$router.push("/painel/meus-dados");
      this.showDropDown();
    },
    signOutLogin: async function() {
      await this.signOut();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.hd-nav-bar {
  font-size: 14px;
  color: #00fffd;
  /* background-color: ; */
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
}
.banner-header {
  height: 100%;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0.25em 0.25em 0.25em 1em;
}
.hd-group-user {
  width: 200px;
}
.user-name {
  float: left;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.bt-dropDown {
  cursor: pointer;
  float: right;
  font-size: 16px;
  background-color: transparent;
  color: #00fffd;
  border: none;
  width: 50px;
  height: 100%;
}
.dropdown-user {
  width: 200px;
  background-color: #242b38;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px 0px 4px 4px;
  overflow: hidden;
  display: table;
  border-top: 3px solid #00fffd;
  transition: 0.2s ease-in-out;
}
.dropdown-user ul {
  list-style: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
}
.dropdown-user ul li {
  padding: 15px 15px;
  width: 100%;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}
.dropdown-user ul li:last-child {
  border-bottom: none;
}
.dropdown-user ul li:hover {
  background-color: #00fffd !important;
  transition: 0.2s ease-in-out;
}
.dropdown-user ul li:hover .reset-bt {
  color: black !important;
}
.user-name {
  color: #00fffd;
}
.group-config {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.group-config a,
.group-config .bt-icon .icon {
  cursor: pointer;
}
.group-config a {
  background-color: #25273c;
  border-radius: 50%;
  padding: 0.4em;
  margin-right: 9px;
  margin-left: 9px;
  position: relative;
}
.bt-icon:hover > .icon {
  color: white;
}
.rt-180 {
  transition: 0.2s ease-in-out;
  transform: rotate(180deg);
}
.rt-45 {
  transition: 0.2s ease-in-out;
  transform: rotate(45deg);
}
</style>
