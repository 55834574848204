<template>
  <div id="app">
    <nav-bar></nav-bar>

    <div class="alignment-content-app">
      <menu-main appName="cms"></menu-main>

      <div class="container-main">
        <div :class="{ container: $mq !== 'sm', 'is-fluid': $mq !== 'sm' }">
          <div class="section"><router-view> </router-view></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// importando os components principais que ficarao nesta pagina
import Menu from "@/components/menu/MenuDesktop";
import NavBar from "@/components/header/NavBar";
import * as Sentry from "@sentry/browser";

export default {
  name: "App",
  components: {
    "nav-bar": NavBar,
    "menu-main": Menu,
  },
  data() {
    return {};
  },
  async created() {
    await this.getMercado();
  },
  mouted() {
    Sentry.setContext("customer", {
      id: this.userInfo.id,
      name: this.userInfo.nome,
      plan: this.userInfo.plano,
    });
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapActions(["getMercado"]),
  },
};
</script>

<style>
@media (min-width: 320px) and (max-width: 1024px) {
  .panel {
    width: 100%;
    height: calc(100% - 50px);
  }
  .container-main {
    position: fixed;
    width: 100%;
    top: 55px;
    vertical-align: top;
    height: calc(100% - 55px);
    color: white;
    overflow-y: auto;
  }
  .alignment-content-app {
    position: fixed;
    height: calc(100% - 55px);
    top: 55px;
    width: 100%;
  }
  .content-body {
    padding: 15px;
    font-size: 14px;
    color: #333;
    display: table;
    background-color: white;
    border-radius: 7px;
  }
}
#content-desktop {
  display: block;
}
#message-mobile {
  display: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}
@media (min-width: 1025px) {
  .menu-2 {
    /*float: left;*/
    z-index: 9999;
    position: fixed;
    top: 0px;
    width: 50px;
    height: 100%;
    background-color: black;
    overflow: hidden;
    transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-property: all;
    -webkit-transition-property: all;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
  }

  .menu-2:hover {
    width: 270px;
  }

  .header {
    /*float: left;*/
    z-index: 9998;
    position: fixed;
    top: 0px;
    left: 80px;
    vertical-align: top;
    height: 50px;
    width: calc(100% - 80px);
    /* background-color: white; */
  }

  .container-main {
    position: fixed;
    top: 55px;
    left: 80px;
    vertical-align: top;
    height: calc(100% - 55px);
    width: calc(100% - 80px);
    color: white;
    overflow-y: auto;
  }

  .alignment-content-app {
    position: fixed;
    height: calc(100% - 55px);
    top: 55px;
    width: 100%;
  }

  /* Estilos relacionados ao conteudo do site */
  .content {
    padding: 15px;
    width: 100%;
  }
  .panel {
    width: 100%;
    height: 100%;
    /* background-color: rgba(106, 231, 106, 0.466); */
  }
  .content-body {
    padding: 15px;
    font-size: 14px;
    color: #333;
    display: table;
    background-color: white;
    border-radius: 7px;
  }
}
</style>
