<template>
  <div class="sidebar-menu">
    <b-sidebar
      :open.sync="openSideBar"
      position="absolute"
      :expand-on-hover="$mq == 'sm' ? false : true"
      :reduce="$mq == 'sm' ? false : true"
      :mobile="'fullwidth'"
      :right="$mq === 'sm'"
      type="is-bluedark-2"
      fullheight
    >
      <div class="p-1">
        <!--
          <router-link to="/painel/ligas">
            <div class="logo-menu-cp centralize">
              <img src="../../assets/logotipo_green.png" alt="Escala10" />
            </div>
          </router-link>
        -->
        <b-menu v-if="renderMenu" class="is-custom-mobile">
          <!-- Usuarios apenas para mobile -->
          <b-menu-list v-if="$mq === 'sm'" label="Usuário">
            <b-menu-item
              v-if="limitAccess"
              icon-pack="fas"
              icon="cubes"
              @click="handlerRoute(`/renewSignature/${userId}?action=upgrade`)"
              label="Melhorar Plano"
            ></b-menu-item>
            <b-menu-item
              icon-pack="fas"
              icon="user-edit"
              @click="handlerRoute(`/painel/meus-dados`)"
              label="Meus Dados"
            ></b-menu-item>
            <b-menu-item
              icon-pack="fas"
              icon="cubes"
              label="Sair"
              @click="signOutLogin()"
            ></b-menu-item>
          </b-menu-list>

          <b-menu-list label="Menu">
            <restrict-access
              v-for="(item, i) of menuItens"
              :key="'menu-item-' + i"
              :slug="item.p"
              :appName="appName"
            >
              <b-menu-item
                :icon="item.icon"
                icon-pack="fas"
                :active="handlerActive(item)"
                @click="handlerRoute(`${'url' in item ? item.url : ''}`, item)"
                expanded
              >
                <template slot="label" slot-scope="props">
                  <span
                    >{{ item.name }}
                    <b-icon
                      v-if="'children' in item"
                      class="is-pulled-right"
                      :icon="props.expanded ? 'menu-down' : 'menu-up'"
                    ></b-icon
                  ></span>
                </template>

                <restrict-access
                  v-for="(child, ci) of item.children"
                  :key="'menu-item-child-' + ci"
                  :slug="child.p"
                  :appName="appName"
                >
                  <b-menu-item
                    :icon="child.icon"
                    :label="child.name"
                    icon-pack="fas"
                    :active="handlerActiveChildren(child)"
                    @click="handlerRoute(`${'url' in child ? child.url : ''}`)"
                  >
                  </b-menu-item>
                </restrict-access>
              </b-menu-item>
            </restrict-access>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { Menu } from "@/shared/Nav";
import restrictAccess from "@/components/restrictAccess/restrictAccess";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MenuDesktop",
  components: {
    "restrict-access": restrictAccess,
  },
  props: {
    appName: {
      type: String,
      default: () => "painel",
    },
  },
  data() {
    return {
      renderMenu: false,
      isExpanded: [],
      menuItens: [],
      limitAccess: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "openSideBar", "userId"]),
    navlist() {
      return Menu(this.appName)
    },
  },
  created() {
    this.limitAccess = this.$accessControl.checkLimitAccess();
    this.menuItens = this.navlist;
    this.menuItens.map((m) => this.isExpanded.push(false));
    this.renderMenu = true;

    if (this.$mq === "sm") {
      this.troggleSidebar(false);
    }
  },
  methods: {
    ...mapActions(["troggleSidebar", "signOut"]),
    handlerActive(item) {
      if ("children" in item) {
        let a = item.children.filter((e) => this.$route.path.includes(e.url));
        return a.length > 0 ? true : false;
      } else return item.url.includes(this.$route.path);
    },
    handlerActiveChildren(child) {
      return child.url.includes(this.$route.path);
    },
    signOutLogin: async function () {
      await this.signOut();
      this.$router.push({ path: "/login" });
    },
    async handlerRoute(url, item) {
      if (item) {
        if (!item.children) {
          if (this.$mq === "sm") await this.troggleSidebar(!this.openSideBar);
        }
      } else {
        if (this.$mq === "sm") await this.troggleSidebar(!this.openSideBar);
      }

      if (url !== "") this.$router.push(url);
    },
  },
};
</script>
<style lang="scss">
.logo-menu-cp img {
  max-height: 120px;
}
.logo-menu-cp {
  padding-bottom: 0.5em;
}
.label-menu {
  font-size: 0.9em;
  margin-left: 5px;
}
.p-1 {
  padding: 1em;
}

.menu-list li a span {
  font-size: 0.85em;
}
.menu-list li a .icon {
  margin-right: 10px;
  font-size: 0.8em;
}
.sidebar-menu {
  position: relative;
  // top: 55px;
  // left: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  // margin-top: 55px;
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                    font-size: 0.9em;
                    span:last-child {
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
