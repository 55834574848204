var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"navbar-header"}},[_c('b-navbar',{attrs:{"fixed-top":_vm.fixedTop,"transparent":"","mobile-burger":false}},[_c('template',{slot:"brand"},[_c('div',{staticClass:"columns is-multiline is-gapless is-mobile is-centered is-vcentered"},[_c('div',{staticClass:"column is-full"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ path: '/ligas' }}},[_c('img',{style:(_vm.$mq == 'sm' ? 'width: 75px;' : ''),attrs:{"src":require("../../assets/imagens/logotipo_green_10.png"),"alt":"Escala 10"}})])],1)])]),_c('template',{slot:"start"},[(_vm.$mq != 'sm')?_c('div',[(_vm.showHeader && _vm.limitAccess)?_c('div',{staticClass:"banner-header"},[_c('div',[_vm._v(" Sua Assinatura possui um acesso limitado, sem permissão para algumas telas,"),_c('br'),_vm._v(" ou expirando antes do final da temporada. "),_c('router-link',{attrs:{"to":'/renewSignature/' + _vm.userId + '?action=upgrade'}},[_c('b',{staticClass:"has-text-white"},[_vm._v("Clique Aqui")])]),_vm._v(" para atualizar a Assinatura! ")],1)]):_vm._e()]):_vm._e()]),_c('template',{slot:"end"},[_c('div',{staticClass:"group-config"},[(_vm.renderMercado && (_vm.$mq !== 'sm' || !_vm.openSideBar))?_c('timer',{style:(_vm.$mq == 'sm' ? 'width: 150px; transform: scale(0.85,0.85);' : ''),attrs:{"clock":false,"starttime":_vm.startTime,"endtime":_vm.endTime,"trans":{
            days: 'dia(s)',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
            running: 'fecha em',
            expired: '',
            status: {
              running: 'MERCADO ABERTO!',
              expired: 'MERCADO FECHADO!',
            },
          }}}):_vm._e(),(_vm.$mq != 'sm')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'Telegram',
            classes: 'home-tooltip',
          }),expression:"{\n            content: 'Telegram',\n            classes: 'home-tooltip',\n          }",modifiers:{"bottom":true}}],attrs:{"href":"https://t.me/escala10","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"telegram-plane"}})],1):_vm._e(),(_vm.$mq != 'sm')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'Whatsapp',
            classes: 'home-tooltip',
          }),expression:"{\n            content: 'Whatsapp',\n            classes: 'home-tooltip',\n          }",modifiers:{"bottom":true}}],attrs:{"href":"https://api.whatsapp.com/send?phone=5521974399122","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"whatsapp"}})],1):_vm._e(),(_vm.$mq != 'sm')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'Facebook',
            classes: 'home-tooltip',
          }),expression:"{\n            content: 'Facebook',\n            classes: 'home-tooltip',\n          }",modifiers:{"bottom":true}}],attrs:{"href":"https://www.facebook.com/escala10.br/","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"facebook"}})],1):_vm._e(),(_vm.$mq != 'sm')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'Instagram',
            classes: 'home-tooltip',
          }),expression:"{\n            content: 'Instagram',\n            classes: 'home-tooltip',\n          }",modifiers:{"bottom":true}}],attrs:{"href":"https://www.instagram.com/escala10.br/","target":"_blank"}},[_c('b-icon',{attrs:{"pack":"fab","icon":"instagram"}})],1):_vm._e(),_c('NotificationAlert'),(_vm.$mq === 'sm')?_c('b-button',{staticStyle:{"margin-left":"15px","transition":"all 0.2s ease"},attrs:{"id":"button-icon","icon-left":_vm.openSideBar ? 'times' : 'bars',"icon-pack":"fas","type":"is-darkblue"},on:{"click":function($event){return _vm.troggleSidebar(!_vm.openSideBar)}}},[(_vm.openSideBar)?_c('span',[_vm._v("Menu")]):_vm._e()]):_vm._e()],1),(_vm.$mq != 'sm')?_c('b-navbar-item',{attrs:{"tag":"div","id":"group-user-dropdown"}},[_c('b-dropdown',{ref:"dropdown",attrs:{"position":"is-bottom-left"},on:{"active-change":function (active) { return (_vm.dropDown = active); }},model:{value:(_vm.dropDown),callback:function ($$v) {_vm.dropDown=$$v},expression:"dropDown"}},[_c('div',{staticClass:"hd-group-user",attrs:{"slot":"trigger"},slot:"trigger"},[_c('label',{staticClass:"user-name"},[_vm._v("Olá, "+_vm._s(_vm._f("formatterName")(_vm.userInfo.nome)))]),_c('button',{staticClass:"reset-bt bt-dropDown",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-angle-down icon-user",class:{ 'rt-180': _vm.dropDown }})])]),_c('div',{staticClass:"dropdown-user"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.gotoMeuDados()}}},[_c('button',{staticClass:"reset-bt",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-user-edit"}),_vm._v(" Meus Dados ")])]),_c('li',{on:{"click":function($event){return _vm.signOutLogin()}}},[_c('button',{staticClass:"reset-bt",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v(" Sair ")])])])])])],1):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }